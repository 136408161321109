import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
const FlatHeader = () => {
  const router = useRouter()
  const { status } = useSession()

  return (
    <header className="site-header">
      <div className="header-layout container mx-auto px-4 flex justify-between">
        <div className="header-item header-item--logo">
          <div className="site-header__logo">
            <span className="visually-hidden">myfruitsale.com</span>
            <a
              href="https://myfruitsale.com"
              className="site-header__logo-link"
            >
              <img
                className="small--hide mt-2.5"
                src="//cdn.shopify.com/s/files/1/0648/0864/8945/files/LOGO_-_myfruitsale_1_bdbbccce-5a11-4b50-9693-f887eb9b28ef_260x.png?v=1654724701"
                srcSet="//cdn.shopify.com/s/files/1/0648/0864/8945/files/LOGO_-_myfruitsale_1_bdbbccce-5a11-4b50-9693-f887eb9b28ef_260x.png?v=1654724701 1x, //cdn.shopify.com/s/files/1/0648/0864/8945/files/LOGO_-_myfruitsale_1_bdbbccce-5a11-4b50-9693-f887eb9b28ef_260x@2x.png?v=1654724701 2x"
                alt="myfruitsale.com"
                itemProp="logo"
              />
              <img
                className="medium-up--hide mt-4"
                src="//cdn.shopify.com/s/files/1/0648/0864/8945/files/LOGO_-_myfruitsale_1_bdbbccce-5a11-4b50-9693-f887eb9b28ef_140x.png?v=1654724701"
                srcSet="//cdn.shopify.com/s/files/1/0648/0864/8945/files/LOGO_-_myfruitsale_1_bdbbccce-5a11-4b50-9693-f887eb9b28ef_140x.png?v=1654724701 1x, //cdn.shopify.com/s/files/1/0648/0864/8945/files/LOGO_-_myfruitsale_1_bdbbccce-5a11-4b50-9693-f887eb9b28ef_140x@2x.png?v=1654724701 2x"
                alt="myfruitsale.com"
              />
            </a>
          </div>
        </div>
        <div className="header-item header-item--navigation">
          <div className="md:hidden block"></div>
          <div className="axillary-nav">
            {status == 'authenticated' ? (
              <button
                className="axillary-nav__link"
                onClick={() => signOut({ callbackUrl: '/' })}
              >
                <i className="fa-regular fa-user"></i>
                <span>Log Out</span>
              </button>
            ) : (
              <button
                className="axillary-nav__link"
                onClick={() => router.push('/')}
              >
                <i className="fa-regular fa-user"></i>
                <span>Log In</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
export default FlatHeader
