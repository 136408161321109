import FlatHeader from 'components/FlatHeader'
import React from 'react'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = {hasError: false}
  }

  //   error
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return {hasError: true}
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({error, errorInfo})
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <FlatHeader></FlatHeader>
          <div className="container mx-auto p-4">
            <h2>Oops, there is an error!</h2>
            <button
              className="btnOutline"
              type="button"
              onClick={() => this.setState({hasError: false})}
            >
              Try again?
            </button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
