import '../globals.css'

import ErrorBoundary from 'components/ErrorBoundry'
import NextNProgress from 'nextjs-progressbar'
import React from 'react'
import {SessionProvider} from 'next-auth/react'

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({Component, pageProps: {session, ...pageProps}}) {
  return (
    <SessionProvider session={session} refetchInterval={5 * 60}>
      <ErrorBoundary FallbackComponent={'ErrorFallback'}>
        <NextNProgress />
        <Component {...pageProps} />
      </ErrorBoundary>
    </SessionProvider>
  )
}
